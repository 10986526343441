













import { defineComponent } from '@vue/composition-api'

import SettingImportModal from '@/components/templates/h/SettingImportModal.vue'
import PageView from '@/components/templates/h/yosan-haibun/dsp-info/PageView.vue'
import {
  TemplateFile,
  ResourceType,
  Where,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
} from '@/types/generated/graphql'
import { EXPORT_FILENAME } from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'

export default defineComponent({
  components: { PageView, SettingImportModal },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  data() {
    return {
      resourceType: ResourceType.UiPDspInfo,
      templateFile: TemplateFile.UiPDspInfo_01,
      outputCondition: {
        delOutput: [],
      },
    }
  },
  methods: {
    /*********************************
     * 以下はインポートリクエスト用の処理
     *********************************/
    requestImport() {
      console.log('requestImport')
      this.$refs.settingImportModal.show()
    },
    /**
     * インポート開始処理
     */
    async importFileUpload(files: File[]) {
      console.log('importFileUpload', { files })
      this.helper.import({ files: files, resourceType: this.resourceType })
    },
    /**
     * Query用のorderByパラメータ構築
     */
    _createOrderByString() {
      const orderBy: OrderBy[] = [
        {
          field: 'storeCd',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'ctgCode',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'ptnCode',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'standNo',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'shelfupNo',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
      ]
      console.log({ orderBy })
      return this.helper.generateQueryOrderByString(orderBy)
    },

    /**
     * エクスポート処理
     */
    async requestExport() {
      const resourceType = this.resourceType
      const where: Where[] = []
      const rowQuery = `
        query q {
          vUiPDspInfos(
            orderBy: ${this._createOrderByString()}
          ) {
            edges {
              node {
                inactiveFlg
                storeCd
                ctgCode
                ptnCode
                standNo
                shelfupNo
                certain0ShelfCd
                configuredFlg
                lastModifiedUserName
                lastModifiedDatetime
              }
            }
          }
        }`

      const query = this.helper.generateQueryString({
        rowQuery,
        resourceType,
        where,
      })
      await this.helper.export({
        query: query,
        jmespathQuery:
          'data.vUiPDspInfos.edges[].node | map(&merge(`{"__update": null}`, @), @)',
        templateFile: this.templateFile,
        filenameExport: EXPORT_FILENAME.dsp_info,
      })
    },
  },
})
